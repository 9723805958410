<template>
	<section class="section wow">
		<div class="content">
			<div class="university-registration-block">
				<h3 class="block-title">
					Регистрация на очное мероприятие "Лифт в будущее" для студентов <br>
					Воронежского государственного университета <br>
					20 апреля
				</h3>
				<div class="text">
					<p>
						Заполни форму и участвуй в очном мероприятии "Лифт в будущее" для студентов ВГУ! <br>
						Все подробности пришлем в письме.
					</p>
				</div>
				<form
					class="form"
					novalidate
					@submit.prevent="registration"
				>
					<div class="field-wrap">
						<label class="field-label">
							<input
								v-model.trim="$v.regForm.first_name.$model"
								type="text"
								class="field"
								placeholder="Имя"
								style="text-transform: capitalize"
							>
							<div class="label">Имя</div>
							<span
								v-if="$v.regForm.first_name.$error"
								class="help-block form-error"
							>
								{{
									!$v.regForm.first_name.required ? 'Это обязательное поле' : $v.regForm.first_name.alphaSpace || 'Только буквы, пробел и дефис'
								}}
							</span>
						</label>
					</div>
					<div class="field-wrap">
						<label class="field-label">
							<input
								v-model.trim="$v.regForm.last_name.$model"
								type="text"
								class="field"
								placeholder="Фамилия"
								style="text-transform: capitalize"
							>
							<div class="label">Фамилия</div>
							<span
								v-if="$v.regForm.last_name.$error"
								class="help-block form-error"
							>
								{{
									!$v.regForm.last_name.required ? 'Это обязательное поле' : $v.regForm.last_name.alphaSpace || 'Только буквы, пробел и дефис'
								}}
							</span>
						</label>
					</div>
					<div class="field-wrap">
						<label class="field-label">
							<input
								v-model.trim="regForm.post"
								type="text"
								class="field"
								placeholder="Должность"
							>
							<div class="label">Должность</div>
						</label>
					</div>
					<div class="field-wrap">
						<label class="field-label">
							<input
								v-model.trim="$v.regForm.edu_fac.$model"
								type="text"
								class="field"
								placeholder="Наименование учреждения"
							>
							<div class="label">Наименование учреждения</div>
							<span
								v-if="$v.regForm.edu_fac.$error"
								class="help-block form-error"
							>Это обязательное поле</span>
						</label>
					</div>
					<div class="field-wrap">
						<label class="field-label">
							<c-select
								:name="'Город'"
								autosearch-city
								:default-data="regForm.city"
								:is-error="$v.regForm.city.$error"
								@selected="selectCity"
							/>
						</label>
					</div>
					<div class="field-wrap">
						<label class="field-label">
							<input
								v-model.trim="$v.regForm.email.$model"
								type="text"
								class="field"
								placeholder="Email"
							>
							<div class="label">Email</div>
							<span
								v-if="$v.regForm.email.$error"
								class="help-block form-error"
							>
								{{ !$v.regForm.email.required ? 'Это обязательное поле' : !$v.regForm.email.email ? 'Введите корректный e-mail' : 'Ошибка поля' }}
							</span>
						</label>
					</div>
					<div class="field-wrap sm">
						<label class="checkbox-filter">
							<input
								v-model="$v.isPersonal.$model"
								type="checkbox"
								@change="$v.isPersonal.$touch()"
							>
							<span class="info">Соглашаюсь с условиями обработки <a
								href="/privacy-policy"
								target="_blank"
							>персональных данных</a></span>
							<div
								v-if="$v.isPersonal.$error"
								class="help-block form-error"
							>Необходимо подтверждение
							</div>
						</label>
					</div>
					<div class="field-wrap">
						<label class="checkbox-filter">
							<input
								v-model="$v.isAgree.$model"
								type="checkbox"
								@change="$v.isAgree.$touch()"
							>
							<span class="info">Соглашаюсь с условиями <a
								href="/agreement"
								target="_blank"
							>пользовательского соглашения</a></span>
							<div
								v-if="$v.isAgree.$error"
								class="help-block form-error"
							>Необходимо подтверждение</div>
						</label>
					</div>
					<button
						v-if="!isSended"
						id="btnRegin_14"
						class="button orange lg btnRegistration"
					>
						Зарегистрироваться
					</button>
					<div
						v-else
						class="button orange lg button-preloader"
					>
						<div class="button-preloader-icons">
							<div class="button-preloader-icon" />
							<div class="button-preloader-icon" />
							<div class="button-preloader-icon" />
						</div>
					</div>
				</form>
			</div>
		</div>
	</section>
</template>

<script>

import {required, email, sameAs} from 'vuelidate/lib/validators'
import {alphaSpace} from '@/assets/scripts/custom_validators'

export default {
    data: () => ({
        isPersonal: false,
        isAgree: false,
        isSended: false,
        isCityError: false,
        regForm: {
            first_name: '',
            last_name: '',
            post: '',
            edu_fac: '',
            city: '',
            email: ''
        }
    }),
    validations: {
        regForm: {
            first_name: {
                required,
                alphaSpace
            },
            last_name: {
                required,
                alphaSpace
            },
            edu_fac: {required},
            city: {required},
            email: {
                required,
                email
            }
        },
        isPersonal: {
            sameAs: sameAs(() => true)
        },
        isAgree: {
            sameAs: sameAs(() => true)
        }
    },
    methods: {
        selectCity(val) {
            if (val) this.isCityError = false
            if (!val) this.isCityError = true
            this.regForm.city = val;
        },
        registration() {
            this.$v.$touch()
            if (this.$v.$invalid) {
                console.log('Error in Reg form')
            } else if (!this.isSended) {
                this.isSended = true;
                this.$store.dispatch('user/reginEvent', {
                    data: {
                        first_name: this.regForm.first_name,
                        last_name: this.regForm.last_name,
                        post: this.regForm.post,
                        study: {
                            education: 'Высшее',
                            education_facility: {
                                name: this.regForm.edu_fac
                            }
                        },
                        city: {
                            name: this.regForm.city
                        },
                        education_facility: {
                            name: 'string'
                        },
                        email: this.regForm.email,
                        external_service: {
                            slug: 'voronezhskij-gu'
                        }
                    },
                    path: '/?modal_open=eventSuccess'
                })
                    .then(content => !IS_DEV && !IS_TEST ? window.location.href = content.path : false)
                    .catch(error => this.modalOpen('regUniverFail', error))
                    .finally(() => this.isSended = false)
            }
        }
    }
}
</script>
